<template>
  <div class="pay-table"
       :class="{
        'sm-table': $vuetify.breakpoint.sm,
        'xs-table': $vuetify.breakpoint.xs,
    }"
  >
    <div class="form-table-custom with-padding mt-2">
      <table style="width: 100%;">
        <thead>
        <tr>
          <th style="width: 50px"></th>
          <th style="width: 40px">№</th>
          <th style="width: 120px">Місяць</th>
          <th style="width: 120px">Сума</th>
          <th style="width: 200px">Послуга</th>
          <th>Коментар</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="`item-${item.row_num}-${index}`">
          <td style="width: 50px;">
            <v-checkbox
                class="mt-1 pt-0 ml-3"
                color="success"
                hide-details :ripple="false"
                :value="selected_row === item.row_num"
                :disabled="item.month !== current_month"
                :indeterminate="item.month !== current_month"
                :indeterminate-icon="'mdi-lock-outline'"
                @change="changeSelect(item.row_num)"
            />
          </td>
          <td style="width: 40px;">{{ item.row_num }}</td>
          <td style="width: 120px; font-weight: 500;">{{ item.month | formatDate('MMMM. YY') }}</td>
          <td style="width: 120px; font-weight: 500;"
              :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
          >
            {{ item.sum | formatNumber }}
          </td>
          <td style="width: 200px">
            <div style="display: flex; align-items: center;">
              <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3 ml-1">
                {{ getAccountIcon(item.icon, 'icon') }}
              </v-icon>
              <div style="line-height: 15px; text-align: left">
                {{ item.service_name }}
              </div>
            </div>
          </td>
          <td style="font-style: oblique; text-align: left; margin-left: 4px;">
            {{ item.comment }}
          </td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="6"
              class="success--text"
              style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
          >
            Архів одноразових нарахувань порожній. Після створення - дані відображатимуться автоматично
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate} from "@/filters";
import oneTimeChargeAPI from "@/utils/axios/one_time_charge"
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";

export default {
  name: "OneTimeArchive",
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updateOneTimeArchive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth'
    }),
    currentSelect() {
      return this.selected_row
    }
  },
  data() {
    return {
      selected_row: 0,
      expand_row_num: 0,
      modal_watch: null,
      update_watch: null,
      items: [],
      modal_item: {}
    }
  },
  methods: {
    ...mapActions({
      fetchAccountArchive: GET_ARCHIVE,
      fetchFlatServices: GET_ALL_WITH_BALANCE,
    }),
    getAccountIcon,
    formatDate,
    fetchArchive() {
      const payload = { person_hash: this.person_hash, date_start: this.date_start }
      oneTimeChargeAPI.get_by_flat(payload)
        .then(response => response.data)
        .then(data => {
          this.items = data.map((item, idx) => {
            return Object.assign(item, {row_num: idx + 1, error: false})
          })
          if (this.updateOneTimeArchive) {
            this.$emit('afterOneTimeArchiveUpdate')
          }
        })
    },
    modal_watcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.fetchArchive()
              } else {
                this.items = []
                this.modal_item = {}
                this.selected_row = 0
              }
            }
          }
      )
    },
    update_watcher() {
      this.watcher = this.$watch(
          'updateOneTimeArchive',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.selected_row = 0
                this.modal_item = {}

                this.fetchArchive()
                this.fetchAccountArchive(this.$route.params.person_hash)
                this.fetchFlatServices(this.$route.params.person_hash)

                this.$emit('afterOneTimeArchiveUpdate')
              }
            }
          }
      )
    },
    buildPayloadForModal() {
      const row = this.items.find(item => item.row_num === this.selected_row)
      if (row) {
        this.modal_item = {
          person_hash: this.person_hash,
          month: row.month,
          sum: row.sum,
          service_id: row.service_id,
          comment: row.comment,
          id: row.id,
          volume: row.volume
        }
      } else {
        this.modal_item = {}
      }
    },
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0
        this.modal_item = {}
      } else {
        this.selected_row = row_num
        this.buildPayloadForModal()
      }
      this.$emit('selectRow', this.modal_item)
    },
  },
  created() {
    this.modal_watcher()
    this.update_watcher()
  },
  beforeDestroy() {
    if (this.modal_watch) {
      this.modal_watch()
    }
    if (this.update_watch) {
      this.update_watch()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: rgba(0, 0, 0, .12);
$expand-border-color: rgba(0, 0, 0, .05);
$expand-background-col: #f0f0f0;

.expand-icon:focus:after {
  opacity: 0 !important;
}

.select-checkbox {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.sm-table {

}
.xs-table {

}
</style>